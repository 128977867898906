import React, { Fragment, useState } from 'react'
import styled, { withTheme } from 'styled-components'
import TitleTopic from '../../../../components/TitleTopic'
import CategoryArticlesPanel from '../../../../components/CategoryArticlesPanel'
import Block from '../../../../layouts/Blocks'
import ItemArticle from '../../../Article/components/ItemArticle'
import PropTypes from 'prop-types'
import hasItems from '../../../../modules/has-items'
import LoadMore from '../../../../components/LoadMore'
import { htmlParser, toKebabCase } from '../../../../modules/parse'
import ContainerFull from '../../../../components/ContainerFull'

const Wrapper = styled(ContainerFull)``

const HtmlWrapper = styled(ContainerFull)`
  font-family: ${(props) => props.theme.font.primary};
  font-size: ${(props) => props.theme.fontSize.text};
  color: ${(props) => props.theme.color.textPrimary};
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  margin-bottom: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
`

const ListTopic = ({ apiUrl, data: { title, articles, html, backgroundColor }, disableTabs, ...props }) => {
  const [helperArticles, setArticles] = useState(articles)
  return (
    <Wrapper noGapEdge cinemaMode={backgroundColor && { backgroundPrimary: backgroundColor }} {...props}>
      {title && <TitleTopic view="screen" title={title} />}
      {hasItems(html) && (
        <HtmlWrapper className={`page-${toKebabCase(title).toLowerCase()}`}>{htmlParser(html)}</HtmlWrapper>
      )}
      {hasItems(articles) && (
        <CategoryArticlesPanel disable={disableTabs} articles={helperArticles}>
          {(categoryArticles) => (
            <Fragment>
              <Block data={{ articles: [categoryArticles[0]] }} view="big_one_article" noLazyLoading={true} />
              <Block
                data={{ articles: [categoryArticles[1], categoryArticles[2], categoryArticles[3]] }}
                view="three_landscape_article"
                adIds={props.theme.mode === 'tablet' ? ['S5', 'S6'] : ['S5']}
                noLazyLoading={true}
              />
              <Block
                data={{ articles: [categoryArticles[4], categoryArticles[5], categoryArticles[6]] }}
                view="three_landscape_article"
                adIds={props.theme.mode !== 'tablet' && ['S6']}
              />
              <Block data={{ articles: categoryArticles.slice(7) }} view="articles" />
              <LoadMore
                articles={categoryArticles}
                afterLoadMore={(newArticles) => {
                  setArticles(newArticles)
                }}
                loadMoreRoute={apiUrl}
              />
            </Fragment>
          )}
        </CategoryArticlesPanel>
      )}
    </Wrapper>
  )
}

ListTopic.propTypes = {
  data: PropTypes.shape({
    ...TitleTopic.propTypes,
    articles: PropTypes.arrayOf(ItemArticle.propTypes),
    backgroundColor: PropTypes.string,
    html: PropTypes.string,
  }),
  disableTabs: PropTypes.bool,
}

export default withTheme(ListTopic)
